/* eslint-disable no-use-before-define */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"

import { useAppSelector } from "store/storeHooks"
import styled from "styled-components"
import { FormLogin } from "features/users"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PageLogin() {
    const userReducer = useAppSelector((state) => state.connectedUser)

    return (
        <div className="row col-12 border border-success m-1">
            {userReducer.username}
            <StyledPageLogin>
                <h1 className="app-title mb-4">SL.zedixi.com</h1>
                <img className="app-logo mb-4" alt="SL" src="/img/app-logo/app-logo-512.png" />
                <FormLogin />
            </StyledPageLogin>
        </div>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledPageLogin = styled.div`
    /*color: $C1d1 !important;*/
    text-align: center;
    margin: 0 auto;

    @media all and (min-width: 320px) {
        padding: 60px 0;
        max-width: 320px;
        .app-title {
            font-size: 1.5em;
        }
        .app-logo {
            width: 200px;
        }
    }

    @media all and (max-width: 319px) {
        padding: 10px 0;
        .app-title {
            font-size: 1.1em;
        }
        .app-logo {
            width: 60%;
        }
    }
`
