/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
import { createSlice } from "@reduxjs/toolkit"
import { apiLogin } from "helpers/api"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

interface ConnectedUserState {
    userToken: string | null
    userId: string | null
    username: string | null
    userStatus: string
    loginError: string | null
}

const initialState: ConnectedUserState = {
    userToken: localStorage.getItem("userToken"),
    userId: localStorage.getItem("userId"),
    username: localStorage.getItem("userName"),
    userStatus: "ANONYMOUS", // "ANONYMOUS", //	"ANONYMOUS", "LOGGING", "LOGGED"
    loginError: null,
}

const slice = createSlice({
    name: "user",
    initialState,
    reducers: {
        loginStart: (state, action) => {
            console.log("___ loginStart . reducers", action)
            state.userToken = null
            state.userId = null
            state.username = null
            state.userStatus = "LOGGING"
        },
        loginSuccess: (state, action) => {
            console.log("___ loginSuccess . reducers", action)

            localStorage.setItem("userToken", action.payload.token)
            localStorage.setItem("userId", action.payload.id)
            localStorage.setItem("userName", action.payload.pseudo)

            state.userToken = action.payload.token
            state.userId = action.payload.id
            state.username = action.payload.pseudo
            state.userStatus = "LOGGED"
            state.loginError = null
        },
        loginError: (state, action) => {
            console.log("___ loginSuccess . reducers", action)

            localStorage.removeItem("userToken")
            localStorage.removeItem("userId")
            localStorage.removeItem("userName")

            state.userToken = null
            state.userId = null
            state.username = null
            state.userStatus = "ANONYMOUS"
            state.loginError = action.payload
        },
        logoutSuccess: (state) => {
            console.log("___ logoutSuccess . reducers")

            localStorage.removeItem("userToken")
            localStorage.removeItem("userId")
            localStorage.removeItem("userName")

            state.userToken = null
            state.userId = null
            state.username = null
            state.userStatus = "ANONYMOUS"
            state.loginError = null
        },
    },
})
export default slice.reducer

// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■

// Actions
const { loginStart, loginSuccess, logoutSuccess, loginError } = slice.actions

export const login = (username: string, password: string) => async (dispatch: any) => {
    console.log("___ login . action", username)
    dispatch(loginStart({ username, password }))
    apiLogin(username, password).then((reponse) => {
        console.log("reponse xxxx", reponse)
        if (reponse.user) {
            console.log("reponse.user", reponse.user)
            dispatch(loginSuccess(reponse.user))
        } else if (reponse.error) {
            if (reponse.error === "E0011003_USERNAME_NOT_FOUND") {
                dispatch(loginError("Nom d'utilisateur introuvable"))
            } else if (reponse.error === "E0011004_WRONG_PASSWORD") {
                dispatch(loginError("Mot de passe incorrect"))
            }
            console.log("err", reponse.error)
        }
    })
    return null
}

export const logout = () => async (dispatch: any) => {
    console.log("___ logout . action")
    dispatch(logoutSuccess())
}
