// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useEffect } from "react"

import { fetchArticles } from "store/articlesReducer"
import "bootstrap/dist/css/bootstrap.min.css"
import { useDispatch } from "react-redux"
import { useAppSelector } from "store/storeHooks"

import NavMenu from "layouts/NavMenu"
import ArticlesListe from "features/articles/ArticlesListe"
import { Loading } from "ui"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

function PageArticlesIndex() {
    const dispatch = useDispatch()
    const articlesrReducer = useAppSelector((state) => state.listes)

    useEffect(() => {
        dispatch(fetchArticles(1))
    }, [dispatch])

    console.log("articlesrReducer", articlesrReducer)

    return (
        <div className="row col-12 border border-success m-1 p-2 bg-warning">
            <NavMenu />
            <h2>PageArticlesIndex</h2>
            {articlesrReducer.isFetching ? <Loading /> : <ArticlesListe />}
        </div>
    )
}

export default PageArticlesIndex
