/* eslint-disable prefer-template */
const useLocal = false
export const API_BASE_URL = useLocal ? "http://kn.zedixi.local/apiz" : "https://kn.zedixi.com/apiz"

// const debugApi = false

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
/*
function requestOptionsHeaders() {
    //let token = useSelector((state) => state.connectedUser.userToken)
    return {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("userToken"),
    }
}
*/
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export async function apiLogin(username: string, password: string) {
    let user = null
    let error = null
    const url = API_BASE_URL + "/login"
    console.log("url:", url)
    console.log("username", username)
    console.log("password", password)

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify({
            username,
            password,
        }),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.apixError) {
            error = rep.apixError
        } else if (rep.user) {
            user = rep.user
        } else {
            console.log("rep--------------------:" + rep)
            error = "Erreur de connexion inconnue"
        }
    } catch (err) {
        error = "Catch: " + err
    } finally {
        // eslint-disable-next-line no-unsafe-finally
        return {
            user,
            error,
        }
    }
}

export async function apiListeShow(listeId: number) {
    let liste = null
    let articles = null
    let error = null
    const url = API_BASE_URL + "/listex/" + listeId
    console.log("url apiListeShow:", url)

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        /*
        body: JSON.stringify({
            username,
            password,
        }),
        */
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.apixError) {
            error = rep.apixError
        } else if (rep.articles && rep.liste) {
            articles = rep.articles
            liste = rep.liste
        } else {
            console.log("rep--------------------:", rep)
            error = "Erreur de connexion inconnue"
        }
        /*
        console.log("■■■ rep ■■■")
        console.log(rep)
        console.log("■■■■■■")
        */
    } catch (err) {
        error = "Catch: " + err
    } finally {
        // eslint-disable-next-line no-unsafe-finally
        return {
            liste,
            articles,
            error,
        }
    }
}

export async function apiAddArticleToListe(listeId: number, articleId: number) {
    let articleListe = null
    let error = null
    const url = API_BASE_URL + "/liste/" + listeId + "/add_article"
    console.log("url apiAddArticleToListe:", url)

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify({
            articleId,
        }),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()

        if (rep.apixError) {
            error = rep.apixError
        } else if (rep.articleListe) {
            articleListe = rep.articleListe
        } else {
            console.log("rep--------------------:", rep)
            error = "Erreur de connexion inconnue"
        }
        console.log("■■■ rep ■■■")
        console.log(rep)
        console.log("■■■■■■")
    } catch (err) {
        error = "Catch: " + err
    } finally {
        // eslint-disable-next-line no-unsafe-finally
        return {
            articleListe,
            error,
        }
    }
}

export async function apiDeleteArticleListe(articleListeId: number) {
    let success = false
    let error = null
    const url = API_BASE_URL + "/article_liste/" + articleListeId + "/delete"
    console.log("url apiDeleteArticleListe:", url)

    const requestOptions = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        /*
        body: JSON.stringify({
            articleId,
        }),
        */
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()

        if (rep.apixError) {
            error = rep.apixError
        } else if (rep.message === "OK") {
            success = true
        } else {
            console.log("rep--------------------:", rep)
            error = "Erreur de connexion inconnue"
        }
        console.log("■■■ rep ■■■")
        console.log(rep)
        console.log("■■■■■■")
    } catch (err) {
        error = "Catch: " + err
    } finally {
        // eslint-disable-next-line no-unsafe-finally
        return {
            success,
            error,
        }
    }
}

export async function apiCreateArticle(article: {
    nom: string
    description: string | null
    onlyCat: boolean
}) {
    console.log("$$$$$$$$$$$$$$$$$ apiCreateArticle, ", article)
    let articleReturn = null
    let error = null
    const url = API_BASE_URL + "/article/create"
    console.log("url apiCreateArticle:", url)

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify(article),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()

        if (rep.apixError) {
            error = rep.apixError
        } else if (rep.article) {
            articleReturn = rep.article
        } else {
            console.log("rep--------------------:", rep)
            error = "Erreur de connexion inconnue"
        }
        console.log("■■■ rep ■■■")
        console.log(rep)
        console.log("■■■■■■")
    } catch (err) {
        error = "Catch: " + err
    } finally {
        // eslint-disable-next-line no-unsafe-finally
        return {
            article: articleReturn,
            error,
        }
    }
}

export async function apiUpdateArticle(article: {
    id: number
    nom: string
    description: string | null
    onlyCat: boolean
}) {
    console.log("$$$$$$$$$$$$$$$$$ apiUpdateArticle, ", article)
    let articleReturn = null
    let error = null
    const url = API_BASE_URL + "/article/" + article.id + "/update"
    console.log("url apiUpdateArticle:", url)

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify(article),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()

        if (rep.apixError) {
            error = rep.apixError
        } else if (rep.article) {
            articleReturn = rep.article
        } else {
            console.log("rep--------------------:", rep)
            error = "Erreur de connexion inconnue"
        }
        console.log("■■■ rep ■■■")
        console.log(rep)
        console.log("■■■■■■")
    } catch (err) {
        error = "Catch: " + err
    } finally {
        // eslint-disable-next-line no-unsafe-finally
        return {
            article: articleReturn,
            error,
        }
    }
}

export async function apiDeleteArticle(articleId: number) {
    let success = false
    let error = null
    const url = API_BASE_URL + "/article/" + articleId + "/delete"
    console.log("url apiDeleteArticle:", url)

    const requestOptions = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        /*
        body: JSON.stringify({
            articleId,
        }),
        */
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()

        if (rep.apixError) {
            error = rep.apixError
        } else if (rep.message === "OK") {
            success = true
        } else {
            console.log("rep--------------------:", rep)
            error = "Erreur de connexion inconnue"
        }
        console.log("■■■ rep ■■■")
        console.log(rep)
        console.log("■■■■■■")
    } catch (err) {
        error = "Catch: " + err
    } finally {
        // eslint-disable-next-line no-unsafe-finally
        return {
            success,
            error,
        }
    }
}
