// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector } from "store/storeHooks"
import ArticleListeItem from "./ArticleListeItem"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ArticlesListe() {
    const { liste } = useAppSelector((state) => state.listes)
    const nbreArticles = liste ? liste.articleListes.length : 0

    return (
        <div className="row col-12">
            {nbreArticles < 1 ? (
                <div>Aucun article dans cette liste</div>
            ) : (
                <div>
                    {nbreArticles} article{nbreArticles > 1 && "s"} dans la liste:
                </div>
            )}

            {liste?.articleListes.map((articleListe) => (
                <ArticleListeItem key={`al-${articleListe.id}`} articleListe={articleListe} />
            ))}
        </div>
    )
}
