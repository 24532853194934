/* eslint-disable react/jsx-props-no-spreading */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { Bouton } from "ui"
import { IcoEdit } from "styles/icones"

import { /* useSelector, */ useDispatch } from "react-redux"
import { setSelectedArticle, ArticleInterface } from "store/listesReducer"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
    article: ArticleInterface
    className: string | null
}

export default function ButtonArticleEdit({ article, className = "" }: Props) {
    const dispatch = useDispatch()
    return (
        <Bouton
            onClick={() => handleClick()}
            variant="success"
            title="Modifier l'article"
            size="sm"
            className={className}
        >
            <IcoEdit />
        </Bouton>
    )

    function handleClick() {
        dispatch(setSelectedArticle(article))
    }
}
