import { createSlice } from "@reduxjs/toolkit"
import { apiListeShow } from "helpers/api"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

interface ArticleInterface {
    id: number
    nom: string
    description: string | null
}

interface InitialStateInterface {
    articles: Array<ArticleInterface>
    isFetching: boolean
    fetchError: boolean
}

const initialState: InitialStateInterface = {
    articles: [],
    isFetching: false,
    fetchError: false,
}

const slice = createSlice({
    name: "articles",
    initialState,
    reducers: {
        fetchArticlesStart: (state /* , action */) => {
            state.isFetching = true
            state.fetchError = false
        },
        fetchArticlesSuccess: (state, action) => {
            state.articles = action.payload
            state.isFetching = false
            state.fetchError = false
        },
        fetchArticlesError: (state, action) => {
            state.isFetching = false
            state.fetchError = action.payload
        },
        addArticleSuccess: (state, action) => {
            console.log(action)
            state.isFetching = false
            state.fetchError = false
        },
    },
})
export default slice.reducer

// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■

// Actions
const { fetchArticlesStart, fetchArticlesSuccess, fetchArticlesError, addArticleSuccess } =
    slice.actions

export const fetchArticles = (listeId: number) => async (dispatch: any) => {
    dispatch(fetchArticlesStart())
    console.log("___ fetchArticles . action")

    apiListeShow(listeId).then((reponse) => {
        console.log("reponse xxxx", reponse)
        if (reponse.articles) {
            console.log("reponse.articles *****", reponse.articles)
            dispatch(fetchArticlesSuccess(reponse.articles))
        } else if (reponse.error) {
            if (reponse.error === "E0011003_USERNAME_NOT_FOUND") {
                dispatch(fetchArticlesError("Nom d'utilisateur introuvable"))
            } else if (reponse.error === "E0011004_WRONG_PASSWORD") {
                dispatch(fetchArticlesError("Mot de passe incorrect"))
            }
            console.log("err", reponse.error)
        }
    })

    return null
}

export const addArticle = (article: ArticleInterface) => async (dispatch: any) => {
    console.log("addEtab", article)
    dispatch(addArticleSuccess(article))
}
