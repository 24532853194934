import { createSlice } from "@reduxjs/toolkit"
import {
    apiListeShow,
    apiAddArticleToListe,
    apiDeleteArticleListe,
    apiCreateArticle,
    apiUpdateArticle,
    apiDeleteArticle,
} from "helpers/api"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export interface ArticleInterface {
    id: number
    nom: string
    description: string | null
    onlyCat: boolean
}
interface ArticleListesInterface {
    id: number
    article: { id: number }
    inPanier: boolean
    infos: string | null
    quantite: string | null
}
interface ListeInterface {
    id: number
    nom: string
    articleListes: ArticleListesInterface[]
}

interface InitialStateInterface {
    articles: Array<ArticleInterface>
    liste: ListeInterface | null
    isFetching: boolean
    fetchError: boolean
    isLoadingCreateArticle: boolean
    selectedArticle: ArticleInterface | null
}

// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■

const initialState: InitialStateInterface = {
    liste: null,
    articles: [],
    isFetching: false,
    fetchError: false,
    isLoadingCreateArticle: false, // aussi pour update et delete
    selectedArticle: null,
}

const slice = createSlice({
    name: "listes",
    initialState,
    reducers: {
        fetchListeStart: (state) => {
            state.liste = null
            state.articles = []
            state.isFetching = true
            state.fetchError = false
        },
        fetchListeSuccess: (state, action) => {
            state.liste = action.payload.liste
            state.articles = action.payload.articles
            state.isFetching = false
            state.fetchError = false
        },
        addArticleListeToListeSuccess: (state, action) => {
            console.log(action)
            if (state.liste) {
                state.liste.articleListes.push(action.payload)
            }
        },
        deleteArticleListeFromListeSuccess: (state, action) => {
            console.log(action)
            if (state.liste) {
                state.liste.articleListes = state.liste.articleListes.filter(
                    (artListe) => artListe.id !== action.payload
                )
            }
        },
        fetchListeError: (state, action) => {
            state.liste = null
            state.articles = []
            state.isFetching = false
            state.fetchError = action.payload
        },

        setSelectedArticleReducer: (state, action) => {
            state.selectedArticle = action.payload
        },

        createArticleStart: (state) => {
            // aussi pour update et delete
            state.isLoadingCreateArticle = true
        },
        createArticleSuccess: (state, action) => {
            state.isLoadingCreateArticle = false
            state.selectedArticle = null
            if (state.articles) {
                state.articles.push(action.payload)
            }
        },
        updateArticleSuccess: (state, action) => {
            state.isLoadingCreateArticle = false
            state.selectedArticle = null
            state.articles = state.articles.map((art) =>
                art.id === action.payload.id ? action.payload : art
            )
        },
        deleteArticleSuccess: (state, action) => {
            state.isLoadingCreateArticle = false
            state.selectedArticle = null
            state.articles = state.articles.filter((art) => art.id !== action.payload)
            if (state.liste) {
                state.liste.articleListes = state.liste.articleListes.filter(
                    (artListe) => artListe.article.id !== action.payload
                )
            }
        },
    },
})
export default slice.reducer

// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■

// Actions
const {
    fetchListeStart,
    fetchListeSuccess,
    fetchListeError,
    addArticleListeToListeSuccess,
    deleteArticleListeFromListeSuccess,

    setSelectedArticleReducer,

    createArticleStart,
    createArticleSuccess,
    updateArticleSuccess,
    deleteArticleSuccess,

    /* , addListeSuccess */
} = slice.actions

export const fetchListe = (listeId: number) => async (dispatch: any) => {
    dispatch(fetchListeStart())
    apiListeShow(listeId).then((reponse) => {
        console.log("■■■ reponse ■■■")
        console.log(reponse)
        console.log("■■■■■■")
        if (reponse.liste && reponse.articles) {
            dispatch(fetchListeSuccess({ liste: reponse.liste, articles: reponse.articles }))
        } else if (reponse.error) {
            dispatch(fetchListeError(reponse.error))
        } else {
            dispatch(fetchListeError("UNKNONW Err"))
        }
    })

    return null
}

export const addArticleToListe = (listeId: number, articleId: number) => async (dispatch: any) => {
    // dispatch(fetchListeStart())
    apiAddArticleToListe(listeId, articleId).then((reponse) => {
        console.log("■■■ reponse ■■■")
        console.log(reponse)
        console.log("■■■■■■")
        if (reponse.articleListe) {
            dispatch(addArticleListeToListeSuccess(reponse.articleListe))
        } else if (reponse.error) {
            dispatch(fetchListeError(reponse.error))
        } else {
            dispatch(fetchListeError("UNKNONW Err"))
        }
    })
    return null
}
export const deleteArticleListeFromListe = (articleListeId: number) => async (dispatch: any) => {
    // dispatch(fetchListeStart())
    console.log("addArticleToListe")
    apiDeleteArticleListe(articleListeId).then((reponse) => {
        console.log("■■■ reponse ■■■")
        console.log(reponse)
        console.log("■■■■■■")
        if (reponse.success) {
            dispatch(deleteArticleListeFromListeSuccess(articleListeId))
        } else if (reponse.error) {
            dispatch(fetchListeError(reponse.error))
        } else {
            dispatch(fetchListeError("UNKNONW Err"))
        }
    })
    return null
}

export const setSelectedArticle = (article: ArticleInterface) => async (dispatch: any) => {
    dispatch(setSelectedArticleReducer(article))
}

export const submitFormArticle = (article: ArticleInterface) => async (dispatch: any) => {
    dispatch(createArticleStart())

    if (article.id === -1) {
        apiCreateArticle(article).then((reponse) => {
            console.log("■■■ reponse ■■■")
            console.log(reponse)
            console.log("■■■■■■")
            if (reponse.article) {
                dispatch(createArticleSuccess(reponse.article))
            } else if (reponse.error) {
                alert("createArticle error")
                // dispatch(fetchListeError(reponse.error))
            } else {
                alert("createArticle UNKNONW Err")
                // dispatch(fetchListeError("UNKNONW Err"))
            }
        })
    } else {
        apiUpdateArticle(article).then((reponse) => {
            console.log("■■■ reponse ■■■")
            console.log(reponse)
            console.log("■■■■■■")
            if (reponse.article) {
                dispatch(updateArticleSuccess(reponse.article))
            } else if (reponse.error) {
                alert("createArticle error")
                // dispatch(fetchListeError(reponse.error))
            } else {
                alert("createArticle UNKNONW Err")
                // dispatch(fetchListeError("UNKNONW Err"))
            }
        })
    }

    return null
    // dispatch(createArticleSuccess(article))
}
export const deleteArticle = (articleId: number) => async (dispatch: any) => {
    dispatch(createArticleStart())
    apiDeleteArticle(articleId).then((reponse) => {
        console.log("■■■ reponse ■■■")
        console.log(reponse)
        console.log("■■■■■■")
        if (reponse.success) {
            dispatch(deleteArticleSuccess(articleId))
        } else if (reponse.error) {
            dispatch(fetchListeError(reponse.error))
        } else {
            dispatch(fetchListeError("UNKNONW Err"))
        }
    })
    return null
}
/*
export const addListe = (liste: ListeInterface) => async (dispatch: any) => {
    dispatch(addListeSuccess(liste))
}
*/
