/* eslint-disable prefer-template */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector } from "store/storeHooks"
import { addArticleToListe } from "store/listesReducer"
import { useDispatch } from "react-redux"
import { Bouton } from "ui"
import { highlightSearchedText } from "helpers/fonctions"
import { IcoPanierAdd, IcoFalse } from "styles/icones"
// import ArticleItem from "./ArticleItem"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
    article: {
        id: number
        nom: string
        description: string | null
        onlyCat: boolean
    }
    searchedText: string
}

export default function ArticlesSearchResultsItem({ article, searchedText }: Props) {
    const dispatch = useDispatch()

    const listeSelector = useAppSelector((state) => state.listes)
    const listeId = listeSelector.liste?.id
    const articleListesIds = listeSelector.liste?.articleListes.filter(
        (al) => al.article.id === article.id
    )
    const isInListe = articleListesIds && articleListesIds.length > 0
    return (
        <div
            className={
                "row col-12 border border-primary m-1 " + (isInListe ? " bg-success" : " bg-light")
            }
        >
            <div className="col-10">
                <div className="col-12">{highlightSearchedText(article.nom, searchedText)}</div>
                <small className="col-12 text-success">
                    {article.description}
                    {article.onlyCat && <span className="onlyCat">--onlyCat--</span>}
                </small>
            </div>
            {isInListe ? (
                <Bouton onClick={() => addToListe()} variant="warning" className="col-2">
                    <IcoFalse />
                </Bouton>
            ) : (
                <Bouton onClick={() => addToListe()} className="col-2">
                    <IcoPanierAdd />
                </Bouton>
            )}
        </div>
    )

    function addToListe() {
        if (listeId) {
            dispatch(addArticleToListe(listeId, article.id))
        }
    }
}
