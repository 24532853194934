/* eslint-disable prefer-template */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import { useAppSelector } from "store/storeHooks"
import { deleteArticleListeFromListe } from "store/listesReducer"
import { useDispatch } from "react-redux"
import { Bouton } from "ui"
import { IcoFalse } from "styles/icones"
import ButtonArticleEdit from "./ButtonArticleEdit"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
    articleListe: {
        id: number
        inPanier: boolean
        infos: string | null
        quantite: string | null
        article: { id: number }
    }
}

export default function ArticleListeItem({ articleListe }: Props) {
    const dispatch = useDispatch()
    const { articles } = useAppSelector((state) => state.listes)

    const matchArticles = articles.filter((art) => art.id === articleListe.article.id)
    const article = matchArticles.length > 0 ? matchArticles[0] : null

    const [deleteLoading, setDeleteLoading] = useState(false)

    if (article === null) {
        // ca ne devrait pas arriver, mais pour être sûr...
        return <h2>Article non trouvé</h2>
    }

    return (
        <div
            className={
                "row col-12 border border-success m-1  " + (deleteLoading && " bg-secondary")
            }
        >
            <ButtonArticleEdit article={article} className="col-2" />
            <div className="row col-8">
                <span className="nom">{article.nom}</span>

                {article.description && (
                    <small className="description text-success">
                        A.description: {article.description}
                    </small>
                )}
                {articleListe.quantite && (
                    <small className="quantite text-warning">
                        AL.quantite: {articleListe.quantite}
                    </small>
                )}
                {articleListe.infos && (
                    <small className="infos text-info">AL.infos: {articleListe.infos}</small>
                )}
                {article.onlyCat && <small className="onlyCat text-danger">--onlyCat--</small>}
            </div>

            {/*
                <span className="id">#{article.id}</span>
                <span className="id">#{articleListe.id}</span>
                <span className="inPanier">inPanier: {articleListe.inPanier ? "oui" : "non"}</span>   
            */}

            <div className="row col-2">
                {/* eslint-disable-next-line no-alert */}
                <Bouton
                    onClick={() => handleDeleteClick()}
                    variant="warning"
                    size="sm"
                    disabled={deleteLoading}
                >
                    <IcoFalse />
                </Bouton>
            </div>
        </div>
    )

    function handleDeleteClick() {
        setDeleteLoading(true)
        dispatch(deleteArticleListeFromListe(articleListe.id))
    }
}
