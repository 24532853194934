/* eslint-disable jsx-a11y/click-events-have-key-events */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css"

import { useAppSelector } from "store/storeHooks"

import PageArticlesIndex from "pages/PageArticlesIndex"
import PageListeShow from "pages/PageListeShow"
import PageLogin from "pages/PageLogin"
import "styles/global.scss"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function App() {
    const connectedUserToken: string | null = useAppSelector(
        (state) => state.connectedUser.userToken
    )

    return (
        <>
            {!connectedUserToken ? (
                <PageLogin />
            ) : (
                <Router>
                    <div id="app">
                        {/* 
                        <NavHeader /> 
                        */}
                        <RenderSwitchRoute />
                    </div>
                </Router>
            )}
        </>
    )

    function RenderSwitchRoute() {
        return (
            <Routes>
                <Route path="/" element={<PageArticlesIndex />} />
                <Route path="/liste/:id" element={<PageListeShow />} />
            </Routes>
        )
    }
}

/**
 *
 *
 * TODO
 *
 * Recherche avancé d'article (rayons, tapez "*" pour tout afficher)
 * Long press pour editer
 *
 *
 *
 *
 *
 *
 */
