// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { IcoSearch, IcoFalse } from "styles/icones"
import { Bouton } from "ui"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
    searchedText: string
    // eslint-disable-next-line no-unused-vars
    setSearchedText: (text: string) => void
}

export default function ArticlesSearch({ searchedText, setSearchedText }: Props) {
    return (
        <div className="row col-12 mb-2">
            <IcoSearch className="col-1" />
            <input
                type="text"
                value={searchedText}
                onChange={(ev) => setSearchedText(ev.target.value)}
                placeholder="Rechercher un article à ajouter"
                className="col-10"
            />
            <Bouton
                onClick={() => setSearchedText("")}
                disabled={searchedText === ""}
                title="Effacer"
                className="col-1"
            >
                <IcoFalse />
            </Bouton>
        </div>
    )
}
