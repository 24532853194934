/* eslint-disable no-use-before-define */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useEffect, useState } from "react"
import { fetchListe } from "store/listesReducer"
import { useDispatch } from "react-redux"
import { useAppSelector } from "store/storeHooks"
import styled from "styled-components"

import NavMenu from "layouts/NavMenu"
import ArticlesSearch from "features/articles/ArticlesSearch"
import ArticlesSearchResults from "features/articles/ArticlesSearchResults"
import ArticlesListe from "features/articles/ArticlesListe"
import ButtonArticleCreate from "features/articles/ButtonArticleCreate"
import FormArticle from "features/articles/FormArticle"
import { Loading } from "ui"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PageListeShow() {
    const dispatch = useDispatch()
    const listesReducer = useAppSelector((state) => state.listes)
    const [searchedText, setSearchedText] = useState("")
    // const [showFormArticle, setShowFormArticle] = useState(false)

    useEffect(() => {
        dispatch(fetchListe(1))
    }, [dispatch])

    return (
        <StyledPageListeShow className="col-12">
            <NavMenu />
            <h2 className="text-center">
                Liste: {listesReducer.liste && <b>{listesReducer.liste.nom}</b>}
            </h2>
            {listesReducer.isFetching ? (
                <Loading />
            ) : (
                <div className="pageContent">
                    {listesReducer.selectedArticle && <FormArticle />}

                    {!listesReducer.selectedArticle && (
                        <>
                            <ArticlesSearch
                                searchedText={searchedText}
                                setSearchedText={setSearchedText}
                            />

                            {searchedText === "" ? (
                                <ArticlesListe />
                            ) : (
                                <>
                                    <ButtonArticleCreate searchedText={searchedText} className="" />
                                    <ArticlesSearchResults searchedText={searchedText} />
                                </>
                            )}
                        </>
                    )}
                </div>
            )}
        </StyledPageListeShow>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledPageListeShow = styled.div`
    // background-color: orange;
    padding: 0px !important;

    .pageContent {
        // background-color: pink;
        padding: 5px;
        /* min-width: 300px !important; */
        max-width: 500px;
        margin: auto;
    }
`
