/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
import Button from "react-bootstrap/Button"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function Bouton({ onClick, children, ...props }) {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <StyledBouton onClick={onClick} {...props}>
            {children}
        </StyledBouton>
    )
}
// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledBouton = styled(Button)`
    /* background-color: orange; */
`
