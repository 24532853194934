// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector } from "store/storeHooks"
import { simplifyText } from "helpers/fonctions"
import ArticlesSearchResultsItem from "./ArticlesSearchResultsItem"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
    searchedText: string
}

export default function ArticlesSearchResults({ searchedText }: Props) {
    console.log("ArticlesSearch")

    const articles = useAppSelector((state) => state.listes.articles)
    /*
    let articles.sort((a, b) =>
        (a.nom.toLowerCase() > b.nom.toLowerCase() ? 1 : b.nom.toLowerCase() > a.nom.toLowerCase())
            ? -1
            : 0
    )    
*/

    return (
        <div className="row col-12">
            {articles
                .filter((art) => simplifyText(art.nom).includes(simplifyText(searchedText)))
                .sort((a, b) => sortParam(a.nom, b.nom, searchedText))
                .map((article) => (
                    <ArticlesSearchResultsItem
                        article={article}
                        key={article.id}
                        searchedText={searchedText}
                    />
                ))}
        </div>
    )

    function sortParam(strA: string, strB: string, searched: string) {
        /**
         * Trie la liste d'article
         * Tri 1: Le nom commence par searched
         * Tri 2: ordre alphabetique
         */
        strA = simplifyText(strA)
        strB = simplifyText(strB)
        if (strA === strB) {
            return 0
        }
        if (strA.startsWith(searched) && !strB.startsWith(searched)) {
            return -1
        }
        if (strB.startsWith(searched) && !strA.startsWith(searched)) {
            return 1
        }
        return strA < strB ? -1 : 1
    }
}
