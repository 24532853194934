// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

/**
 * @param str: string
 * @returns str: string
 *
 * Passe une chaine de caratère en minuscule et enlève les accents
 */

export function simplifyText(str: string) {
    str = str.toLowerCase()
    // str = str.replace(new RegExp("\\s", "g"), "")
    str = str.replace(new RegExp("[àáâãäå]", "g"), "a")
    str = str.replace(new RegExp("æ", "g"), "ae")
    str = str.replace(new RegExp("ç", "g"), "c")
    str = str.replace(new RegExp("[èéêë]", "g"), "e")
    str = str.replace(new RegExp("[ìíîï]", "g"), "i")
    str = str.replace(new RegExp("ñ", "g"), "n")
    str = str.replace(new RegExp("[òóôõö]", "g"), "o")
    str = str.replace(new RegExp("œ", "g"), "oe")
    str = str.replace(new RegExp("[ùúûü]", "g"), "u")
    str = str.replace(new RegExp("[ýÿ]", "g"), "y")
    // str = str.replace(new RegExp("\\W", "g"), "")
    return str
}

export function highlightSearchedText(str: string, srch: string) {
    const strSimplied = simplifyText(str)
    srch = simplifyText(srch)
    const indexOfSearch = strSimplied.toLowerCase().indexOf(srch)
    const before = str.substring(0, indexOfSearch)
    const highlight = str.substring(indexOfSearch, indexOfSearch + srch.length)
    const after = str.substring(indexOfSearch + srch.length)
    return (
        <>
            {before}
            <b>{highlight}</b>
            {after}
        </>
    )
}

export function capitalizeFirstLetter(str: string) {
    return str[0].toUpperCase() + str.slice(1)
}
