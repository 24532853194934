// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"

import {
    FaRegSmileWink,
    FaSearch,
    FaPlus,
    FaEdit,
    FaBan,
    FaTrashAlt,
    FaHome,
    FaKey,
    FaCheck,
    FaTimes,
    FaExclamationTriangle,
    FaCartArrowDown,
} from "react-icons/fa"

import { VscSplitVertical, VscSplitHorizontal } from "react-icons/vsc"
import { AiOutlineColumnHeight, AiOutlineColumnWidth } from "react-icons/ai"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

// FaKey

interface Props {
    // eslint-disable-next-line react/require-default-props
    className?: string
}

export const IcoBan = ({ className }: Props) => <FaBan className={className} />
export const IcoHome = ({ className }: Props) => <FaHome className={className} />
export const IcoKey = ({ className }: Props) => <FaKey className={className} />

export const IcoAdd = ({ className }: Props) => <FaPlus className={className} />
export const IcoDelete = ({ className }: Props) => <FaTrashAlt className={className} />
export const IcoEdit = ({ className }: Props) => <FaEdit className={className} />

export const IcoSplitVertical = ({ className }: Props) => <VscSplitVertical className={className} />
export const IcoSplitHorizontal = ({ className }: Props) => (
    <VscSplitHorizontal className={className} />
)
export const IcoHeight = ({ className }: Props) => <AiOutlineColumnHeight className={className} />
export const IcoWidth = ({ className }: Props) => <AiOutlineColumnWidth className={className} />

export const IcoUsers = ({ className }: Props) => <FaRegSmileWink className={className} />
export const IcoUser = ({ className }: Props) => <FaRegSmileWink className={className} />
export const IcoUserAdd = ({ className }: Props) => <FaRegSmileWink className={className} />

export const IcoQuestion = ({ className }: Props) => <FaRegSmileWink className={className} />
export const IcoTrue = ({ className }: Props) => <FaCheck className={className} />
export const IcoFalse = ({ className }: Props) => <FaTimes className={className} />
export const IcoDanger = ({ className }: Props) => <FaExclamationTriangle className={className} />
export const IcoSearch = ({ className }: Props) => <FaSearch className={className} />
export const IcoPanierAdd = ({ className }: Props) => <FaCartArrowDown className={className} />

export function IcoAll() {
    return (
        <div className="p-3  d-flex">
            <div className="m-1  col-2">
                <RenderIco name="IcoHome">
                    <IcoHome />
                </RenderIco>
                <RenderIco name="IcoBan">
                    <IcoBan />
                </RenderIco>
                <RenderIco name="IcoKey">
                    <IcoKey />
                </RenderIco>
            </div>
            <div className="m-1  col-2">
                <RenderIco name="IcoAdd">
                    <IcoAdd />
                </RenderIco>
                <RenderIco name="IcoEdit">
                    <IcoEdit />
                </RenderIco>
                <RenderIco name="IcoDelete">
                    <IcoDelete />
                </RenderIco>
            </div>
            <div className="m-1  col-2">
                <RenderIco name="IcoUsers">
                    <IcoUsers />
                </RenderIco>
                <RenderIco name="IcoUser">
                    <IcoUser />
                </RenderIco>
                <RenderIco name="IcoUserAdd">
                    <IcoUserAdd />
                </RenderIco>
            </div>
            <div className="m-1  col-2">
                <RenderIco name="IcoSplitVertical">
                    <IcoSplitVertical />
                </RenderIco>
                <RenderIco name="IcoSplitHorizontal">
                    <IcoSplitHorizontal />
                </RenderIco>
                <RenderIco name="IcoHeight">
                    <IcoHeight />
                </RenderIco>
                <RenderIco name="IcoWidth">
                    <IcoWidth />
                </RenderIco>
            </div>
            <div className="m-1  col-2">
                <RenderIco name="IcoQuestion">
                    <IcoQuestion />
                </RenderIco>
                <RenderIco name="IcoTrue">
                    <IcoTrue />
                </RenderIco>
                <RenderIco name="IcoFalse">
                    <IcoFalse />
                </RenderIco>
                <RenderIco name="IcoDanger">
                    <IcoDanger />
                </RenderIco>
                <RenderIco name="IcoSearch">
                    <IcoSearch />
                </RenderIco>{" "}
                <RenderIco name="IcoPanierAdd">
                    <IcoSearch />
                </RenderIco>
                {/*
                <RenderIco name="IcoXxxxxxxx"><IcoXxxxxxxx /></RenderIco>
                <RenderIco name="IcoXxxxxxxx"><IcoXxxxxxxx /></RenderIco>
                <RenderIco name="IcoXxxxxxxx"><IcoXxxxxxxx /></RenderIco>
                <RenderIco name="IcoXxxxxxxx"><IcoXxxxxxxx /></RenderIco>
                <RenderIco name="IcoXxxxxxxx"><IcoXxxxxxxx /></RenderIco>
                <RenderIco name="IcoXxxxxxxx"><IcoXxxxxxxx /></RenderIco>
                <RenderIco name="IcoXxxxxxxx"><IcoXxxxxxxx /></RenderIco>
                */}
            </div>
        </div>
    )
}

interface RenderIcoProps {
    name: string
    children: any
}

function RenderIco({ name, children }: RenderIcoProps) {
    return (
        <div className="col-12">
            {name}: {children}
        </div>
    )
}

/*




import { SplitVertical,  SplitHorizontal} from "styles/icones"
import { IcoAdd } from "styles/icones"



*/
