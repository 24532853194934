/* eslint-disable no-use-before-define */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import { /* useSelector, */ useDispatch } from "react-redux"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import styled from "styled-components"
// import { apiLogin } from "api/api"
import Loading from "ui/Loading"
// import { login } from "store/connectedUserReducer"
import { useAppSelector } from "store/storeHooks"

import { login } from "store/connectedUserReducer"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function FormLogin() {
    const dispatch = useDispatch()
    const userReducer = useAppSelector((state) => state.connectedUser)

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState(null)

    // TODO edrsere
    // ? fsqdfsdfsd
    // ! sdfsdfszdfs

    // fsdfsdfs

    // let userReducer = useSelector((state) => state.connectedUser)

    // console.log(userReducer)

    return (
        <StyledLoginForm onSubmit={handleSubmit}>
            <h1>Login</h1>
            <Form.Group size="lg" controlId="email">
                <Form.Label>Nom d&apos;utilisateur</Form.Label>
                <Form.Control
                    autoFocus
                    type="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
            </Form.Group>

            <Form.Group size="lg" controlId="password" className="mt-2 mb-4">
                <Form.Label>Mot de passe</Form.Label>
                <Form.Control
                    type="password"
                    value={password}
                    autoComplete="on"
                    onChange={(e) => setPassword(e.target.value)}
                />
            </Form.Group>

            {userReducer.userStatus === "LOGGING" ? (
                <Loading message="Connexion" />
            ) : (
                <Button block="true" size="lg" type="submit" disabled={!validateForm()}>
                    Connexion
                </Button>
            )}

            {userReducer.loginError && (
                <>
                    <h2>Error</h2>
                    {userReducer.loginError}
                </>
            )}

            {error && (
                <div className="text-danger mt-3">
                    Une erreur s&apos;est produite:
                    <br />
                    <i>{error}</i>
                </div>
            )}
        </StyledLoginForm>
    )

    function validateForm() {
        return username.length > 0 && password.length > 0
    }

    function handleSubmit(event) {
        event.preventDefault()
        dispatch(login(username, password))
        setError(null)
    }
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledLoginForm = styled(Form)``
