/* eslint-disable prefer-template */
/* eslint-disable no-use-before-define */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { LinkContainer } from "react-router-bootstrap"
import { Navbar, Container, Nav /* , NavDropdown, Dropdown */ } from "react-bootstrap"

import { Bouton } from "ui"
import { logout } from "store/connectedUserReducer"
// import { logout } from "store/connectedUserReducer"
import { useDispatch } from "react-redux"
import { FaHome } from "react-icons/fa"
import styled from "styled-components"
// import { theme } from "styles/theme"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function NavMenu() {
    // const user = useSelector((state) => state.connectedUser)
    // const dispatch = useDispatch()
    const dispatch = useDispatch()

    return (
        <StyledNavbar
            expand="lg"
            className="app-navbar navbar-expand-sm navbar-dark bg-primary bg-gradient "
        >
            <Container fluid>
                <Navbar.Brand>
                    <img src="/img/app-logo/app-logo-32.png" alt="cmz" />
                    <span className="app-title">SL</span>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto container">
                        <LinkContainer to="/">
                            <Nav.Link>
                                <FaHome /> Mes Listes
                            </Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/liste/1">
                            <Nav.Link>
                                <FaHome /> Liste1Show
                            </Nav.Link>
                        </LinkContainer>
                        {/*
                        <LinkContainer to={"/liste/" + 2}>
                            <Nav.Link>&gt; {etab.nom} &gt;</Nav.Link>
                        </LinkContainer>

                        {user.userId && user.userId == 4 && (
                            <NavDropdown
                                title={
                                    <>
                                        <FaBan /> Admin
                                    </>
                                }
                                id="basic-nav-dropdown"
                                className="ms-auto"
                                align="end"
                            >
                                <LinkContainer to="/admin/users">
                                    <NavDropdown.Item>
                                        <FaUserFriends /> Gestion des utilisateurs
                                    </NavDropdown.Item>
                                </LinkContainer>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/about">
                                    <FaQuestion /> A propos
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/dev/test">
                                    <FaRoute /> Test
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.4" disabled>
                                    <FaRoute /> disabled
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}

                        {user.userId && (
                            <NavDropdown
                                title={user.userName}
                                id="user-basic-nav-dropdown"
                                className="ms-auto"
                                align="end"
                            >
                                <NavDropdown.Item onClick={() => btLogoutClick()}>
                                    <FaSignOutAlt /> Déconnexion
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#todo" disabled>
                                    <FaSignOutAlt /> Déconnecter de tous les appareils
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href={"/user/" + user.userId}>
                                    Mon profil
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}
                        */}

                        <Bouton
                            className="ms-auto"
                            onClick={() => dispatch(logout())}
                            variant="danger"
                        >
                            Logggout
                        </Bouton>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </StyledNavbar>
    )

    /*                       
    function btLogoutClick() {
        // dispatch({ type: "LOG_OUT" })
        dispatch(logout())
    }
    */
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledNavbar = styled(Navbar)`
    /*background-color: $    {theme.colors.c1} !important;*/
    /*$extend .bg-primary; */
    width: 100% !important;

    .navbar-brand {
        font-size: 1em;
        img {
            width: 30px;
            height: 30px;
        }
        .app-title {
            font-size: 0.8em !important;
            padding-left: 5px;
        }
    }

    .navbar-collapse {
    }

    .container-fluid {
        width: 100% !important;
        margin-left: 0px;
    }
    .container {
        width: 100% !important;
        margin-left: 0px;
    }

    .nav-link {
        font-size: 0.9em !important;
    }

    #user-basic-nav-dropdown {
        margin-left: auto !important;
    }
`
