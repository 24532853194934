/* eslint-disable no-use-before-define */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import { /* useSelector, */ useDispatch } from "react-redux"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import styled from "styled-components"
// import { apiLogin } from "api/api"
import { useAppSelector } from "store/storeHooks"
import Loading from "ui/Loading"
// import { login } from "store/connectedUserReducer"
// import { useAppSelector } from "store/storeHooks"

import { IcoAdd, IcoFalse, IcoEdit, IcoDelete } from "styles/icones"
import { submitFormArticle, setSelectedArticle, deleteArticle } from "store/listesReducer"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

// eslint-disable-next-line react/prop-types
export default function FormArticle() {
    const dispatch = useDispatch()
    // const userReducer = useAppSelector((state) => state.connectedUser)
    const { isLoadingCreateArticle, selectedArticle } = useAppSelector((state) => state.listes)

    const [nom, setNom] = useState(selectedArticle.nom)
    const [description, setDescription] = useState(selectedArticle.description)
    const [onlyCat, setOnclyCat] = useState(selectedArticle.onlyCat)
    const [error, setError] = useState(null)

    const editMode = selectedArticle.id !== -1

    // TODO edrsere
    // ? fsqdfsdfsd
    // ! sdfsdfszdfs

    return (
        <StyledLoginForm onSubmit={handleSubmit}>
            {editMode ? (
                <h3>
                    Modifier l&apos;article <b>{selectedArticle.nom}</b>
                    {editMode && (
                        <Button
                            block="true"
                            size="sm"
                            variant="danger"
                            disabled={isLoadingCreateArticle}
                            className="float-end"
                            onClick={() => handleDeleteClick()}
                        >
                            <IcoDelete /> Supprimer
                        </Button>
                    )}
                </h3>
            ) : (
                <h1>Créer un article</h1>
            )}
            <Form.Group size="lg" controlId="nom">
                <Form.Label>Nom</Form.Label>
                <Form.Control
                    autoFocus
                    type="text"
                    value={nom}
                    autoComplete="off"
                    onChange={(e) => setNom(e.target.value)}
                />
            </Form.Group>
            <Form.Group size="lg" controlId="description">
                <Form.Label>description</Form.Label>
                <Form.Control
                    type="text"
                    value={description || ""}
                    autoComplete="off"
                    onChange={(e) => setDescription(e.target.value)}
                />
            </Form.Group>
            <Form.Group size="lg" controlId="onlyCat">
                <Form.Label>onlyCat</Form.Label>
                <Form.Check
                    type="checkbox"
                    checked={onlyCat}
                    onChange={() => setOnclyCat(!onlyCat)}
                />
            </Form.Group>
            {isLoadingCreateArticle ? (
                <Loading message="ffffffffffff" />
            ) : (
                <div className="mt-4">
                    <Button block="true" size="lg" type="submit" disabled={!validateForm()}>
                        {editMode ? (
                            <>
                                <IcoEdit /> Modifier l&apos;article
                            </>
                        ) : (
                            <>
                                <IcoAdd /> Créer l&apos;article
                            </>
                        )}
                    </Button>
                    <Button
                        onClick={() => dispatch(setSelectedArticle(null))}
                        block="true"
                        size="lg"
                        variant="danger"
                        className="float-end"
                    >
                        <IcoFalse /> Annuler
                    </Button>
                </div>
            )}
            {/*
            {userReducer.loginError && (
                <>
                    <h2>Error</h2>
                    {userReducer.loginError}
                </>
            )}
            */}
            {error && (
                <div className="text-danger mt-3">
                    Une erreur s&apos;est produite:
                    <br />
                    <i>{error}</i>
                </div>
            )}
        </StyledLoginForm>
    )

    function validateForm() {
        return nom.length > 0
    }

    function handleSubmit(event) {
        event.preventDefault()
        dispatch(submitFormArticle({ id: selectedArticle.id, nom, description, onlyCat }))
        setError(null)
    }

    function handleDeleteClick() {
        // eslint-disable-next-line no-alert
        if (!window.confirm("Voulez-vous vraiment supprimer cet article ?")) {
            return
        }
        dispatch(deleteArticle(selectedArticle.id))
        setError(null)
    }
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledLoginForm = styled(Form)`
    background-color: cyan;
    padding: 5px;
`
