/* eslint-disable react/jsx-props-no-spreading */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { Bouton } from "ui"
import { IcoAdd } from "styles/icones"

import { /* useSelector, */ useDispatch } from "react-redux"
import { capitalizeFirstLetter } from "helpers/fonctions"
import { setSelectedArticle } from "store/listesReducer"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
    searchedText: string
    className: string
}

export default function ButtonArticleCreate({ searchedText = "", className = "" }: Props) {
    const dispatch = useDispatch()
    return (
        <Bouton onClick={() => handleClick()} className={className} variant="success">
            <IcoAdd /> Créer un article
        </Bouton>
    )

    function handleClick() {
        const newArticle = {
            id: -1,
            nom: capitalizeFirstLetter(searchedText),
            description: null,
            onlyCat: false,
        }
        console.log("newArticle: - 1", newArticle)
        dispatch(setSelectedArticle(newArticle))
    }
}
